<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <div class="logo-container">
            <router-link to="/" class="d-flex align-center">
              <!-- <h2 class="text-2xl font-weight-semibold">this town needs</h2> -->

              <v-img :src="require('@/assets/images/logos/ttn_logo2.png')" max-width="100" class="logo"></v-img>
            </router-link>
          </div>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              outlined
              label="帳戶"
              hide-details
              class="mb-3"
              v-on:keyup.enter="Login"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="密碼"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              v-on:keyup.enter="Login"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <!-- <v-text-field
              v-model="email"
              outlined
              label="Email"
              hide-details
              class="mb-3"
              :rules="[value => !!value || 'Required.']"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[value => !!value || 'Required.']"
            ></v-text-field> -->

            <router-link to="/">
              <v-btn block color="primary" class="mt-6" @click="Login"> Login </v-btn>
            </router-link>

            <!-- <v-btn block color="primary" class="mt-6" @click="Login"> Login </v-btn> -->
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-alert style="position: fixed; top: 0" type="error" class="mt-5" :value="alert" transition="slide-y-transition">
      用戶名稱或密碼錯誤
    </v-alert>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    isPasswordVisible: false,
    alert: false,
    username: '',
    password: '',
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),
  methods: {
    ...mapActions(['getData']),
    async Login() {
      // this.alert = true
      if (this.username == '' || this.password == '') {
        return
      }
      try {
        //await commit('setUser', User.get('username'))
        let params = new URLSearchParams()
        params.set(
          'login',
          JSON.stringify({
            email: this.username,
            password: this.password,
          }),
        )
        //let result = await getData(params)
        let send_data = []
        send_data['params'] = params
        send_data['method'] = 'getLoginData'
        this.getData(send_data)
      } catch (error) {
        console.log(error)
      }

      // location.href = '/member-management';
    },
  },
  computed: mapGetters(['login_status', 'login_data']),
  watch: {
    // login_status: function (newVal, oldval) {
    //   console.log(newVal)
    //   // if(newVal){
    //   //   this.$router.push({ name: 'Members' })
    //   // }
    // },
    login_data: function (newVal, oldval) {
      console.log(newVal)
      console.log(newVal=="")
      if (newVal == '') {
        this.alert = true
      } else if (newVal != null) {
        this.$router.push('/member-management')
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.logo-container {
  background: #000;
  width: 100%;
}

.logo {
  margin: 0px auto;
}
</style>
